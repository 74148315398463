@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 100 300;
  src: url('/fonts/Saira-Light.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Saira-Regular.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Saira';
  font-style: normal;
  font-weight: 500 900;
  src: url('/fonts/Saira-Bold.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Chakra-Petch';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/ChakraPetch-Medium.ttf') format('opentype');
  font-display: swap;
}
@font-face {
  font-family: 'Proxima-Soft';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/ProximaSoft-Bold.ttf') format('opentype');
  font-display: swap;
}
